import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Device from "../components/media/Device";
import InputText from "../components/parts/contactForm/InputText";
import Radio from "../components/parts/contactForm/Radio";
import PageTitle from "../components/parts/PageTitile";
import { Seo } from "../components/seo";
import { ScontactDiv } from "../styled/ScontactDiv";
import { StyledOrderP } from "../styled/SText";

const ContactForm = () => {
  const [emailState, setEmailState] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const onChangeEmail = (e) => {
    setEmailState(e.target.value);
  };
  const onChangeConfirmEmail = (e) => {
    setConfirmEmail(e.target.value);
  };
  const onBulurHandler = () => {
    if (emailState !== confirmEmail) {
      alert("メールアドレスが同じではありません");
    } else {
    }
  };

  return (
    <Layout>
      <PageTitle title="お問い合わせフォーム" subTitle="contact" />
      <StyledContactDiv>
        <form
          name="kuu form"
          method="POST"
          data-netlify="true"
          action="/success"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="kuu form" />
          <InputText
            text="お名前"
            id="name"
            type="text"
            name="氏名"
            placeholder="例）空羽 花子"
            required
          />
          <ScontactDiv>
            <div className="title">
              <label htmlFor="email">メールアドレス</label>
              <span className="require">必須</span>
            </div>
            <input
              type="email"
              id="email"
              name="メールアドレス"
              placeholder="例）kuukiyo0312@gmail.com"
              onChange={(e) => {
                onChangeEmail(e);
              }}
              required
            ></input>
            <p className="explanation">
              携帯アドレスの場合、迷惑メールとして扱われ返信ができない場合があります。入力の際はPCアドレスのほうでお願いします。
            </p>
          </ScontactDiv>
          <ScontactDiv>
            <div className="title">
              <label htmlFor="confirm">メールアドレス確認</label>
              <span className="require">必須</span>
            </div>
            <input
              id="confirm"
              name="メールアドレス確認"
              type="email"
              style={
                emailState !== confirmEmail
                  ? { border: "1px solid rgb(204, 88, 88)" }
                  : { border: "1px solid #d5d5d5" }
              }
              onChange={(e) => {
                onChangeConfirmEmail(e);
              }}
              onBlur={onBulurHandler}
              required
            ></input>
          </ScontactDiv>
          <StyledRadioWrapperDiv>
            <div className="require">
              <div>お問い合わせ内容</div>
              <span>必須</span>
            </div>
            <div className="container">
              <Radio text="ご自宅への発送" id="home" name="kind" required />
              <Radio text="ご贈答用としてのご利用" id="gift" name="kind" />
              <Radio text="お茶会・イベントでのご利用" id="event" name="kind" />
              <Radio text="その他" id="other" name="kind" />
            </div>
          </StyledRadioWrapperDiv>
          <ScontactDiv>
            <div className="title">
              <label htmlFor="num">ご注文予定の個数</label>
              <span className="any">任意</span>
            </div>
            <input
              id="num"
              name="ご注文予定の個数"
              type="number"
              min={0}
              placeholder="例）6"
            ></input>
          </ScontactDiv>
          <ScontactDiv>
            <div className="title">
              <label htmlFor="date">お届け希望日</label>
              <span className="any">任意</span>
            </div>
            <input id="date" name="お届け希望日" type="date"></input>
            {/* <p className="explanation">説明文を書き込む</p> */}
          </ScontactDiv>
          <ScontactDiv>
            <div className="title">
              <label htmlFor="contents">備考</label>
              <span className="any">任意</span>
            </div>
            <textarea
              id="contents"
              name="備考："
              rows="5"
              placeholder="お問い合わせ内容を入力して下さい"
            ></textarea>
          </ScontactDiv>

          <StyledChexbox>
            <input
              id="privacy"
              name="プライバシーポリシー"
              type="checkbox"
              required
            />
            <div className="title">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://kuu-wagashi.com/privacy-policy"
              >
                プライバシーポリシー
              </a>
              <label htmlFor="privacy">に同意する</label>

              <span className="require">必須</span>
            </div>
          </StyledChexbox>
          <ScontactDiv className="hidden">
            <label htmlFor="bf">コメント</label>
            <input name="bot-field" id="bf"></input>
          </ScontactDiv>
          <StyledOrderP>
            業務時間外（19時～9時）にお問い合わせされた場合すぐのご返信が難しい場合があります。あらかじめご了承ください。
          </StyledOrderP>
          <StyledFormButton type="submit">送信</StyledFormButton>
        </form>
      </StyledContactDiv>
    </Layout>
  );
};

const StyledChexbox = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 3.5rem;
  a {
    text-decoration: underline;
    color: var(--link-color);
  }
  .require {
    margin-left: 1rem;
    padding: 0.1rem 0.2rem;
    font-size: 1.4rem;
    color: var(--red-color);
    border: 1px solid var(--red-color);
  }
`;
const StyledContactDiv = styled.div`
  width: 90%;
  margin: auto;
  max-width: 70rem;
  @media ${Device.tablet} {
  }
`;
const StyledRadioWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  gap: 1.5rem;
  .require {
    display: flex;
    gap: 1rem;
    span {
      padding: 0.1rem 0.2rem;
      font-size: 1.4rem;
      color: var(--red-color);
      border: 1px solid var(--red-color);
    }
  }
  .container {
    display: flex;
    gap: 1rem 3rem;
    flex-wrap: wrap;
  }
`;
const StyledFormButton = styled.button`
  display: block;
  margin: 0 auto;
  width: 20rem;
  padding: 1rem 3.5rem;
  border: 1px solid var(--black-color);
  color: var(--black-color);
  margin-top: 2rem;
  transition: all var(--transition-3s);
  letter-spacing: 0.4em;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    border: 1px solid var(--sub-color);
    color: var(--sub-color);
  }
`;
export default ContactForm;
export const Head = () => <Seo title={"お問い合わせフォーム"} />;
