import React from "react";
import styled from "styled-components";

const Radio = ({ id, name, text, required }) => {
  return (
    <>
      {required ? (
        <StyledRadioDiv>
          <input
            id={id}
            type="radio"
            name={name}
            value={text}
            required={required}
          />
          <label htmlFor={id}>
            <span className="radio-button"></span>
            {text}
          </label>
        </StyledRadioDiv>
      ) : (
        <StyledRadioDiv>
          <input id={id} type="radio" name={name} value={text} />
          <label htmlFor={id}>
            <span className="radio-button"></span>
            {text}
          </label>
        </StyledRadioDiv>
      )}
    </>
  );
};

const StyledRadioDiv = styled.div`
  label {
    cursor: pointer;
    padding-left: 3rem;
    position: relative;
    span {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      border: 1px solid var(--black-color);
      display: inline-block;
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background-color: var(--black-color);
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }
  input {
    display: none;
    &:checked ~ label span::after {
      opacity: 1;
    }
  }
`;
export default Radio;
