import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const OrderH1Ashirai = () => {
  return (
    <StyledOrderH1Ashirai>
      <div className="triangle"></div>
      <div className="rectangle"></div>
      <div className="square"></div>
    </StyledOrderH1Ashirai>
  );
};

const StyledOrderH1Ashirai = styled.div`
  display: none;
  @media ${Device.laptop} {
    display: flex;
    gap: 1rem;
    .triangle {
      background-color: var(--black-color);
      width: 1.3rem;
      height: 0.7rem;
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }
    .rectangle {
      background-color: var(--black-color);
      width: 25rem;
      height: 0.7rem;
    }
    .square {
      background-color: var(--black-color);
      width: 1.3rem;
      height: 0.7rem;
    }
  }
`;
export default OrderH1Ashirai;
