import styled from "styled-components";

export const ScontactDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  gap: 0.8rem;
  &.hidden {
    visibility: hidden;
    margin-bottom: 0rem;
  }
  .title {
    display: flex;
    gap: 1rem;
    .require {
      padding: 0.1rem 0.2rem;
      font-size: 1.4rem;
      color: var(--red-color);
      border: 1px solid var(--red-color);
    }
    .any {
      padding: 0.1rem 0.2rem;
      font-size: 1.4rem;
      color: var(--black-color);
      border: 1px solid var(--black-color);
    }
  }

  input {
    height: 4.8rem;
    font-size: 1.8rem;
    padding: 1rem;
    font-family: inherit;
    &::placeholder {
      color: var(--gray-color);
    }
    &:focus {
      border: 1px solid var(--black-color);
    }
    /* &:focus:invalid {
      border: 1px solid var(--red-color);
    }
    &:focus:valid {
      border: 1px solid var(--success-color);
    } */
  }
  textarea {
    resize: none;
    padding: 1rem;
    font-size: 1.8rem;
    font-family: inherit;
    &::placeholder {
      color: var(--gray-color);
    }
    &:focus {
      border: 1px solid var(--black-color);
    }
    /* &:focus:invalid {
      border: 1px solid var(--red-color);
    }
    &:focus:valid {
      border: 1px solid var(--success-color);
    } */
  }
  .explanation {
    color: var(--darkGray-color);
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
`;
