import React from "react";
import { ScontactDiv } from "../../../styled/ScontactDiv";

const InputText = ({ text, id, type, name, placeholder, required }) => {
  return (
    <>
      {required ? (
        <ScontactDiv>
          <div className="title">
            <label htmlFor={id}>{text}</label>
            <span className="require">必須</span>
          </div>
          <input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required
          ></input>
        </ScontactDiv>
      ) : (
        <ScontactDiv>
          <label htmlFor={id}>{text}</label>
          <input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
          ></input>
        </ScontactDiv>
      )}
    </>
  );
};

export default InputText;
