import React from "react";
import styled from "styled-components";
import Device from "../media/Device";
import OrderH1Ashirai from "./OrderH1Ashirai";

const PageTitle = ({ title, subTitle = "sub title" }) => {
  let subT = subTitle;
  if (title === "通信販売") {
    subT = "online shop";
  } else if (title === "通常注文") {
    subT = "normal order";
  }
  return (
    <StyledPageTitleDiv>
      <OrderH1Ashirai />
      <div className="titleContainer__title">
        <SH1>{title}</SH1>
        <span className="titleContainer__title--sub">{subT}</span>
      </div>
      <OrderH1Ashirai />
    </StyledPageTitleDiv>
  );
};

const StyledPageTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9rem;
  padding: 10rem 0 9rem;
  .titleContainer {
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      &--sub {
        font-size: 1.8rem;
        color: var(--primary-color);
      }
    }
  }
`;

const SH1 = styled.h1`
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 4.3rem;
  letter-spacing: 0.09em;
  @media ${Device.tablet} {
    font-size: 3rem;
  }
  @media ${Device.laptop} {
    font-size: 3.4rem;
  }
`;

export default PageTitle;
